import './App.css';
import React from 'react';

const Header = React.lazy(() => import('./Modules/Header/Header'));
const WhoAreWe = React.lazy(() => import('./Modules/WhoAreWe/WhoAreWe.js'));
const Team = React.lazy(() => import('./Modules/Team/WEB/Team'));
const MBTeam = React.lazy(() => import('./Modules/Team/Mobile/MBTeam'));
const FormFooter = React.lazy(() =>
  import('./Modules/FormAndFooter/FormFooter')
);
const OurServices = React.lazy(() =>
  import('./Modules/OurServices/OurServices.js')
);
const WhyChooses = React.lazy(() => import('./Modules/WhyChooses/WhyChooses'));
const Clients = React.lazy(() => import('./Modules/Clients/Clients'));
const ServicesPopUp = React.lazy(() =>
  import('./Modules/ServicesPopUp/ServicesPopUp')
);
const Location = React.lazy(() => import('./Modules/Location/Location'));

function App() {
  return (
    <div className='App'>
      <div className='firstBlock'>
          <div className='firstBlockWeb'>
        <Header />
        <ServicesPopUp />
          </div>
          <div className='secondBlockWeb'>
              <WhoAreWe />
          </div>
      </div>
      <div className='App_mobContainer'>
          <div className='thirdBlockWeb'>
              <OurServices />
          </div>
          <div className='fourthBlockWeb'>
              <WhyChooses />
          </div>
      </div>
        <div className='fiveBlockWeb'>
            <Clients />
        </div>
        <div className='sixBlockWeb'>
            <Team />
        </div>
        <div className='sevenBlockWeb'>
            <MBTeam />
            <FormFooter />
            <Location />
        </div>
    </div>
  );
}

export default App;
